document.addEventListener('DOMContentLoaded', function() {

    function getStyleSheet(title) {
        for (let i = 0; i < document.styleSheets.length; i++) {
            const sheet = document.styleSheets[i];
            if (sheet.title === title) {
                return sheet;
            }
        }
        return null;
    }

    function updateLanguageStyle(lang) {
        const stylesheet = getStyleSheet('language');
        if (!stylesheet) return;

        const rules = stylesheet.cssRules || stylesheet.rules;

        // Update the stylesheet rules
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if (rule.selectorText) {
                const selectorLang = rule.selectorText.match(/:lang\((.+?)\)/)[1];
                if(selectorLang){
                    if (rule.selectorText.startsWith('span')) {
                        rule.style.display = selectorLang === lang ? 'inline' : 'none';
                    } else if (rule.selectorText.startsWith('div')) {
                        rule.style.display = selectorLang === lang ? 'block' : 'none';
                    }
                } else {
                    console.warn('language not set up')
                }
            }
        }

        // Update the lang attribute on the <html> element
        document.documentElement.setAttribute('lang', lang);
    }

    function addLanguageSwitchListener() {
        document.querySelectorAll('.language a').forEach(link => {
            link.addEventListener('click', function(event) {
                event.preventDefault();
                const langClass = this.className;
                const lang = langClass.split('_')[0];
                updateLanguageStyle(lang);
            });
        });
    }

    addLanguageSwitchListener();
})
